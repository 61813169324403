<template>
  <div class="join-content">
    <kcTitle title="联系我们" en="Contact Us"></kcTitle>
    <div class="contact-information">
      <div class="join-title">联系方式<span style="color: #18933B" class="font-size-14 pb5">■</span></div>
      <div class="info-title1">为您提供专业问题解答、服务资讯等一对一服务，工作时间：周一至周五，9：00至17：30（节假日除外）</div>
      <div class="information-content flex justify-content-between ">
        <div class="flex justify-content-between" style="width: calc(100% - 220px);margin-left: 30px; margin-top: 60px">
          <div>
            <div>
              <img src="../../../assets/images/Join/img/phone.png" class="mr10" width="15">
              <span class="title4 ">业务合作</span>
            </div>
            <div class="title2 mt10">185-6155-2609</div>
          </div>
          <div>
            <div>
              <img src="../../../assets/images/Join/img/phone.png" class="mr10" width="15">
              <span class="title4 ">售后服务</span>
            </div>
            <div class="title2 mt10">153-7673-6865</div>
          </div>
          <div>
            <div>
              <img src="../../../assets/images/Join/img/phone.png" class="mr10" width="15">
              <span class="title4 ">采购合作</span>
            </div>
            <div class="title2 mt10">198-6222-8902</div>
          </div>
          <div >
            <div>
              <img src="../../../assets/images/Join/img/phone.png" class="mr10" width="15">
              <span class="title4 ">媒体合作</span>
            </div>
            <div class="title2 mt10">156-2105-1102</div>
          </div>
          <div>
            <div>
              <img src="../../../assets/images/Join/img/phone.png" class="mr10" width="15">
              <span class="title4 ">人才招聘</span>
            </div>
            <div class="title2 mt10">176-6095-8313</div>
          </div>
          <div>
            <div>
              <img src="../../../assets/images/Join/img/email.png" class="mr10" width="15">
              <span class="title4 ">联系邮箱</span>
            </div>
            <div class="title2 mt10">kcxd@kcxd-tech.com</div>
          </div>
        </div>
        <div style="width: 140px" class="flex justify-content-end">
          <div class="mb10" style="margin-right: 30px">
            <img src="../../../assets/images/Join/img/wechat.png" width="140">
          </div>
        </div>
      </div>
    </div>
    <div class="line"></div>
    <div class="Ultimate-service">
      <div class="join-title  mb20 ">极致服务<span style="color: #18933B" class="font-size-14 pb5">■</span></div>
      <div class="flex justify-content-between align-items-center">
        <div style="width: 50%">
          <img src="../../../assets/images/Join/img/contactservice.png" width="100%">
        </div>
        <div class="service-content">
          <div class="join-title">服务宗旨</div>
          <div  class="join-title1">以客户为优先，积极快速响应；以市场为导向，聆听客户声音。</div>
          <div class="join-title mt20" >服务原则</div>
          <div class="join-title1">24小时内响应客户需求，48小时内到达现场。</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "view4"
}
</script>

<style lang="scss" scoped>
.join-content {
  padding-top: 81px;
  min-width: 1200px;
  width: 1440px;
  padding-bottom: 60px;

  .contact-information {
    width: 100%;
    margin-top: 60px;
    margin-bottom: 60px;


    .info-title1 {
      margin-top: 36px;
      font-size: 14px;
      font-weight: 400;
      color: #999999;
    }

    .information-content {
      background: linear-gradient(white 15%, #F5F5F5 10%, #F5F5F5);
      justify-content: space-between;

      .title2 {
        font-size: 18px;
        font-weight: 400;
        color: #999999;
      }

      .title4 {
        font-size: 18px;
        font-weight: bold;
        color: #333333;
      }
    }

  }

  .line {
    width: 100%;
    border: 1px solid rgba(153, 153, 153, 0.2)
  }

  .Ultimate-service {
    width: 100%;
    margin-top: 60px;
    margin-bottom: 30px;
    .service-content {
      width: 50%;
      padding-left: 40px;
    }
  }
}

.join-title {
  font-size: 24px;
  font-weight: 600;
  color: #000000;
}
.join-title1{
  font-size: 18px;
  font-weight: 500;
  color: #999999;
  line-height: 36px;
}
</style>
