<template>
<div class="app-container">
  <div style="width: 100%">
    <img src="../../assets/images/Join/img/contactbgimg.png" width="100%">
  </div>
  <div class="flex justify-content-center">
    <view4></view4>
  </div>
  <div class="mt10">
    <bottom />
  </div>

</div>
</template>

<script>
import View4 from "@/views/Join/component/view4";
import bottom from "@/components/bottom";
export default {
  name: "index",
  components: {
    View4,
    // view1,
    // view2,
    // view3,
    bottom
  }
}
</script>

<style scoped>

</style>
